import React, { useEffect, useState } from 'react'
import { graphql } from 'gatsby'
import styled from 'styled-components'
import { config, useSpring, animated } from 'react-spring'
import { space, SpaceProps } from 'styled-system'
import Layout from '../components/layout'
import SEO from '../components/SEO'
import GalleryGrid from '../components/gallery-grid'

const PBox = styled(animated.div)<SpaceProps>`
  ${space}
  max-width: 1400px;
  margin: 0 auto;
`

const Description = styled(animated.div)`
  max-width: 960px;
  letter-spacing: -0.003em;
  --baseline-multiplier: 0.179;
  --x-height-multiplier: 0.35;
  line-height: 1.58;
`

const Title = styled.h1<SpaceProps>`
  ${space}
`

type ImageNode = {
  name: string
  childImageSharp: {
    fluid: {
      aspectRatio: number
      src: string
      srcSet: string
      sizes: string
      base64: string
      tracedSVG: string
      srcWebp: string
      srcSetWebp: string
    }
  }
}

type PageProps = {
  data: {
    gallery: {
      title_detail: string
      color: string
      desc: string
      slug: string
      parent: {
        modifiedTime: string
        birthTime: string
      }
      cover: {
        childImageSharp: {
          resize: {
            src: string
          }
        }
      }
    }
    images: {
      nodes: ImageNode[]
    }
  }
}

function shuffle<T>(array: T[]): T[] {
  let currentIndex = array.length
  let temporaryValue
  let randomIndex

  // While there remain elements to shuffle...
  while (currentIndex !== 0) {
    // Pick a remaining element...
    randomIndex = Math.floor(Math.random() * currentIndex)
    currentIndex -= 1

    // And swap it with the current element.
    temporaryValue = array[currentIndex]
    array[currentIndex] = array[randomIndex]
    array[randomIndex] = temporaryValue
  }

  return array
}

const Gallery: React.FunctionComponent<PageProps> = ({ data: { gallery, images } }) => {
  const animation = useSpring({ config: config.slow, delay: 200, from: { opacity: 0 }, to: { opacity: 1 } })

  const noImages: ImageNode[] = []
  const [imageList, setImageList] = useState(noImages)

  useEffect(() => {
    // this prevents strange state when using page reload, grid aspect ratios were not in
    // sync with the shuffled order of the images.
    setImageList(shuffle([...images.nodes]))
  }, [])

  return (
    <Layout color={gallery.color}>
      <SEO
        pathname={gallery.slug}
        title={`${gallery.title_detail} | Florian Maul Photography`}
        desc={gallery.desc}
        node={gallery.parent}
        banner={gallery.cover.childImageSharp.resize.src}
        individual
      />{' '}
      <PBox pb={6} pt={[0, 0, 0, 6]} pl={[6, 6, 8, 0]} pr={[6]} style={animation}>
        <Title mt={[0, 0, 0, 6]}>{gallery.title_detail}</Title>
        <Description>
          <div dangerouslySetInnerHTML={{ __html: gallery.desc }} />
        </Description>
      </PBox>
      <GalleryGrid
        images={imageList.map((node: any) => ({
          id: node.id,
          ...node.childImageSharp.fluid,
        }))}
        itemsPerRow={[2, 3, 3, 4]}
      />
    </Layout>
  )
}

export default Gallery

export const query = graphql`
  query GalleryTemplate($slug: String!, $images: String!) {
    gallery: galleriesYaml(slug: { eq: $slug }) {
      title_detail
      color
      desc
      slug
      parent {
        ... on File {
          modifiedTime
          birthTime
        }
      }
      cover {
        childImageSharp {
          resize(width: 1200, height: 675, quality: 80) {
            src
          }
        }
      }
    }
    images: allFile(filter: { relativePath: { regex: $images } }, sort: { fields: name, order: ASC }) {
      nodes {
        id
        name
        childImageSharp {
          fluid(quality: 85, maxWidth: 1280) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  }
`
