import React, { useState } from 'react'
import Carousel, { Modal, ModalGateway } from 'react-images'
import styled from 'styled-components'
import Img, { FluidObject } from 'gatsby-image'
import { opacity, OpacityProps } from 'styled-system'
import { chunk, sum } from './array'
import { Box } from '../elements'

interface Props {
  images: {
    id: string
    aspectRatio: number
    src: string
    srcSet: string
    sizes: string
    originalImg: string
    caption: string
    width: string[]
  }[]
  itemsPerRow?: number[]
}

const ImgBox = styled(Box)`
  display: inline-block;
  vertical-align: middle;
  transition: filter 0.3s;
  :hover {
    cursor: pointer;
    filter: contrast(95%) brightness(110%) saturate(105%);
  }
  img {
    outline: 3px solid white;
    outline-offset: -3px;
  }
`

const ImageContainer = styled.div`
  line-height: 0;
  position: relative;
  text-align: center;
  box-sizing: border-box;
`
const CarouselImage = styled.img`
  height: auto;
  max-height: 100vh;
  max-width: 100vw;
  box-sizing: border-box;
`

const PlaceHolderImage = styled.img<OpacityProps>`
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  max-height: 100vh;
  max-width: 100vw;
  box-sizing: border-box;
  object-fit: cover;
  object-position: center center;
  ${opacity}
  object-fit: contain;
`
//   transition-delay: 500ms;

type ViewProps = {
  currentIndex: number
  index: number
  data: {
    source: string
  }
}

export type ImageData = {
  id: string
  base64: string
  srcSet: string
  srcSetWebp: string
  sizes: string
  originalImg: string
  width: string[]
}

const View = (props: ViewProps) => {
  const [placeHolder, placeHolderAction] = useState(1)

  const overscan = 2
  const { currentIndex, index, data } = props
  const inBounds = Math.abs(currentIndex - index) <= overscan
  const imageData: FluidObject = JSON.parse(data.source)

  if (!inBounds) return <></>

  // <PlaceHolderImage aria-hidden="true" opacity={placeHolder} src={imageData.base64} alt="" />
  return (
    <ImageContainer>
      <picture>
        <source type="image/webp" srcSet={imageData.srcSetWebp} />
        <source srcSet={imageData.srcSet} />
        <CarouselImage
          sizes="(min-width: 1280px) 50vw, 100vw"
          alt=""
          onLoad={() => placeHolderAction(0)}
          srcSet={imageData.srcSet}
          src={imageData.src}
        />
      </picture>
    </ImageContainer>
  )
}

const GalleryGrid = ({ images, itemsPerRow: itemsPerRowByBreakpoints = [1] }: Props) => {
  const aspectRatios = images.map(image => image.aspectRatio)
  const rowAspectRatioSumsByBreakpoints = itemsPerRowByBreakpoints.map(itemsPerRow =>
    chunk(aspectRatios, itemsPerRow).map(rowAspectRatios => sum(rowAspectRatios))
  )

  const [modalIsOpen, setModalIsOpen] = useState(false)
  const [modalCurrentIndex, setModalCurrentIndex] = useState(0)

  const closeModal = () => setModalIsOpen(false)
  const openModal = (imageIndex: number) => {
    setModalCurrentIndex(imageIndex)
    setModalIsOpen(true)
  }

  images.forEach((image, i) => {
    const bp = rowAspectRatioSumsByBreakpoints.map((rowAspectRatioSums, j) => {
      const rowIndex = Math.floor(i / itemsPerRowByBreakpoints[j])
      const rowAspectRatioSum = rowAspectRatioSums[rowIndex]

      return `${(image.aspectRatio / rowAspectRatioSum) * 100}%`
    })
    images[i].width = bp
  })

  return (
    <>
      {images.map((image, i) => {
        image.sizes = '(min-width: 1280px) 10vw, 25vw'
        return (
          <ImgBox key={image.id} width={image.width}>
            <a
              href={image.originalImg}
              onClick={(e: React.MouseEvent<HTMLAnchorElement>) => {
                e.preventDefault()
                openModal(i)
              }}
            >
              <Img fluid={image} title={image.caption} loading="lazy" />
            </a>
          </ImgBox>
        )
      })}
      {ModalGateway && (
        <ModalGateway>
          {modalIsOpen && (
            <Modal onClose={closeModal}>
              <Carousel
                views={images.map(image => ({
                  source: JSON.stringify(image),
                }))}
                currentIndex={modalCurrentIndex}
                components={{ View }}
              />
            </Modal>
          )}
        </ModalGateway>
      )}
    </>
  )
}

export default GalleryGrid
